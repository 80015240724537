<template>
  <a-card class="p-4">
    <div class="flex flex-wrap lg:flex-nowrap gap-4 relative">
      <div class="flex flex-col items-center w-full lg:w-56 flex-shrink-0">
          <a-avatar :text="teacher.first_name" :picture="teacher.profile_picture ? teacher.profile_picture.split('/').slice(-1)[0] === 'default-profile-picture.png' ? null : teacher.profile_picture : null" size="72px" />
          <component
            :is="user.isAdminGroup ? 'router-link' : teacher.supervisor_teacher2_id === user.teacher.id ? 'router-link' : 'span'"
            class="text-black text-center break-words truncate" :class="{'cursor-pointer underline': user.isAdminGroup || teacher.supervisor_teacher_id === user.teacher.id}"
            :to="teacher.is_coordinator ? { path: `/coordinators/${teacher.id}` } : { name: 'teachers.show', params: { id: teacher.id } }">
            {{ `${teacher.last_name}, ${teacher.first_name}` }}
          </component>
          <p class="text-center break-words w-full truncate">{{ teacher.phone }}</p>
          <p class="text-center break-words w-full truncate">{{ teacher.email }}</p>
      </div>

      <div class="flex-grow">
        <h4 class="text-black flex flex-wrap justify-center text-lg my-2 lg:my-0 lg:justify-start" v-if="!teacher.is_coordinator">
          {{ lang.teachers.courses.toUpperCase() }}
        </h4>
        <h4 v-else class="text-black flex flex-wrap items-center mb-2 justify-center lg:justify-start mt-2 md:mt-0 lg:mt-0" :class="{'justify-center': teacher.coordinate_specialization, 'justify-between': !teacher.coordinate_specialization}">
          <template v-if="router.createPath.path === 'coordinators/create'">
            <span v-if="teacher.enabled">ESPECIALIDAD </span>
            <template v-if="teacher.specializations.length !=0">
              <a-badge class="ml-2" v-for="spec in mappedSpecializations(teacher)" :key="spec.id">
                <div class="flex">
                  <span class="flex items-center mr-2">{{ spec.title }}</span>
                  <span v-if="user.isAdmin" class="text-red-500 mr-2"> | </span>
                  <span @click="removeCoordinatorSpecialization(spec.id, teacher, spec.title)" v-if="user.isAdmin">
                    <a-icon name="trash" class="text-red-500 flex mt-1 cursor-pointer" />
                  </span>
                </div>
              </a-badge>
              <select class="p-2 border border-gray-200 ml-2 rounded-md" @change="updateCoordinatorSpecialization($event.target.value, teacher)" v-if="user.isAdmin && specializations.length !=0">
                <option value="">-- Asignar otra especialidad --</option>
                <option :value="specialization.id" v-for="specialization in specializations" :key="specialization.id">
                  {{ specialization.title }}
                </option>
              </select>
            </template>
            <template v-else>
              <select class="p-2 border border-gray-200 ml-2 rounded-md" @change="updateCoordinatorSpecialization($event.target.value, teacher)" v-if="user.isAdmin && specializations.length !=0">
                <option value="">-- Asignar especialidad --</option>
                <option :value="specialization.id" v-for="specialization in specializations" :key="specialization.id">
                  {{ specialization.title }}
                </option>
              </select>
              <template v-else>
                <small v-if="teacher.enabled" class="text-gray-600 ml-2 inline-block">Este {{ teacher.is_coordinator ? 'coordinador' : 'profesor' }} no tiene una especialidad</small>
              </template>
            </template>
          </template>
        </h4>
        <template v-if="mappedCourses(teacher).length">
          <div class="flex flex-wrap gap-2 justify-center lg:justify-start cursor-pointer">
            <a-badge v-for="course in mappedCourses(teacher)" :key="course.id" :style="{...course}">
              <component
                :is="user.isAdmin ? 'router-link' : user.isCoordinator ? 'router-link' : 'span'"
                v-tooltip="tooltip(course, teacher.schedules)"
                :to="user.isCoordinator ? { name: 'lessons.studentsNotes', params: {course_id: course.course_id, section_id: course.section_id}} : { name: 'schedules', query: { course: course.course_id, section: course.section_id } }">
                {{ course.title }}

                <template v-if="courseSectionHasSchedules(course, teacher.schedules) && !user.isCoordinator">
                  <a-icon name="calendar" class="ml-2" />
                </template>
              </component>
            </a-badge>
          </div>
        </template>
        <template v-else>
          <div class="flex flex-wrap justify-center lg:justify-start">
          {{ lang.teachers.no_assigned_courses }} en el período actual
          </div>
        </template>
      </div>

      <div class="absolute top-0 right-0" v-if="!user.isStudent">
        <a-dropdown v-if="user.isCoordinator && isSameCoordinator">
          <template #menu >
            <a-dropdown-item :to="{ path: `/coordinators/${teacher.id}` }">
              Ver
            </a-dropdown-item>
            <a-dropdown-item :to="{ path: `/coordinators/${teacher.id}/schedule` }">
              Ver horario
            </a-dropdown-item>
          </template>
        </a-dropdown>
        <a-dropdown v-else-if="user.isAdmin || (user.isCoordinator && $route.path === '/teachers')">
          <template #menu>
            <a-dropdown-item :to="teacher.is_coordinator ? { path: `/coordinators/${teacher.id}` } : { name: 'teachers.show', params: { id: teacher.id } }">
              Ver
            </a-dropdown-item>
            <a-dropdown-item :to="teacher.is_coordinator ? { path: `/coordinators/${teacher.id}/schedule` } : { name: 'teachers.schedule', params: { id: teacher.id } }">
              Ver horario
            </a-dropdown-item>
            <a-dropdown-item v-if="user.isAdmin || user.isLowAdmin" :to="teacher.is_coordinator ? { path: `/coordinators/${teacher.id}/edit` } : { name: 'teachers.edit', params: { id: teacher.id } }">
              Editar
            </a-dropdown-item>
            <a-dropdown-item
                v-if="user.isAdmin || user.isLowAdmin"
                @click="modals.updateEmailModal = true">
              Actualizar correo
            </a-dropdown-item>
            <a-dropdown-item
                @click="sendEmailModal = true"
                v-if="user.isAdmin">
              Enviar correo de confirmación
            </a-dropdown-item>
            <a-dropdown-item v-if="user.isAdmin || user.isLowAdmin" @click="modals.passwordReset = true">
              Cambiar contraseña
            </a-dropdown-item>
            <a-dropdown-item v-if="user.isAdmin || user.isLowAdmin" @click="toggleEnabled(teacher)" :disabled="loadings.enabling">
              {{ teacher.enabled ? 'Desactivar' : 'Activar' }} <a-loader v-if="loadings.enabling" />
            </a-dropdown-item>
            <a-dropdown-item @click="destroy(teacher)" v-if="!teacher.enabled" :disabled="loadings.deleting">
              Eliminar <a-loader v-if="loadings.deleting" />
            </a-dropdown-item>
          </template>
        </a-dropdown>
      </div>
    </div>
    <a-modal
      :show="sendEmailModal"
      @cancel="sendEmailModal = false"
      @confirm="sendEmail(teacher)"
      :confirm-text="'Aceptar'"
    >
      <h3 class="text-center text-lg">¿Desea reenviar el correo de confirmación?</h3>
    </a-modal>
    <a-modal
        :show="modals.passwordReset"
        @cancel="modals.passwordReset = false"
        @confirm="resetPassword(teacher)"
        :title="'Nueva Contraseña'"
        :confirm-text="'Aceptar'"
    >
      <a-input type="password" v-model="new_password">Nueva contraseña</a-input>
    </a-modal>
    <a-modal
      :show="modals.updateEmailModal"
      @cancel="modals.updateEmailModal = false"
      @confirm="updateEmail"
      :confirm-text="'Aceptar'"
    >
      <h3>Actualizar Email</h3>
      <a-input required label="Email" :value="teacher.email" v-model="newEmail"/>
    </a-modal>
  </a-card>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
// TODO revisar si hay alguna forma de saber la especialidad que coordina un coordinador
export default {
  computed: {
    ...mapGetters(['lang']),
    ...mapState({
      // user: ({ session }) => ({...session.user, teacher: {}})
      user: ({ session }) => (session.user)
    }),
    isSameCoordinator () {
      // console.log(this.user.teacher.id === this.teacher.id)
      return this.user.teacher.id === this.teacher.id && this.$route.path === '/coordinators'
    }
  },
  data: () => ({
    loadings: {
      enabling: false,
      deleting: false
    },
    modals: {
      passwordReset: false,
      updateEmailModal: false
    },
    sendEmailModal: false,
    new_password: '',
    newEmail: '',
    specializationIds: [],
  }),
  watch: {
    'modals.updateEmailModal'() {
      this.newEmail = this.teacher.email
    }
  },
  props: {
    period: { required: false, type: Object, default: () => ({}) },
    router: { required: false, type: Object, default: () => ({}) },
    specializations: { required: false, type: Array, default: () => [] },
    teacher: { required: false, type: Object, default: () => ({
        id: null,
        first_name: '',
        last_name: '',
        schedules: [],
        profile_picture: null,
        email: ''
      }) },
    coordinated_specializations: { required: false, type: Array, default: () => [] }
  },
  methods: {
    updateEmail () {
      this.$repository.users
        .updateEmail(this.teacher.user_id, { new_email: this.newEmail })
        .then(() => (this.$emit('updated')))
        .catch((err) => (console.log(err)))
        .finally(() => {
          this.modals.updateEmailModal = false
        })
    },
    tooltip (course, teacher) {
      if (this.user.isAdmin) {
        if(!this.courseSectionHasSchedules(course, teacher)) {
          return 'Asignar horario'
        } else {
          return 'Actualizar horario'
        }
      } else {
        return 'Ver materia'
      }
    },
    test (course_id, section_id) {
      this.route.push({name: 'section_students_courses_notes', params: { section_id: section_id }})
    },
    resetPassword (teacher) {
      this.$repository.users
          .updatePassword(teacher.user_id, { new_password: this.new_password, new_password_confirmation: this.new_password } )
          .then(() => {
            this.$message.success({
              message: 'Se ha actualizado la contraseña correctamente'
            })
          })
          .finally(() => (this.modals.passwordReset = false))
    },
    sendEmail (teacher) {
      this.$repository.users
        .sendConfirmationEmail(teacher.user_id)
        .finally(() => (this.sendEmailModal = false))
    },
    toggleEnabled(teacher) {
      this.$confirm({
        message: `¿Seguro que desea ${teacher.enabled ? 'deshabilitar' : 'habilitar'} este ${teacher.is_coordinator ? 'coordinador' : 'profesor'}?`,
        button: {
          no: 'No',
          yes: 'Si'
        },
        callback: confirm => {
          if (confirm) {
            this.loadings.enabling = !this.loadings.enabling
            this.$repository.teachers
              .toggleEnabled(teacher.id, { enabled: !teacher.enabled })
              .then(({ data }) => {
                
                this.$message.success({
                  message: `${data.first_name} ${data.last_name} se ha ${data.enabled ? 'habilitado' : 'deshabilitado'}`,

                })

                this.$emit('updated')
              })
              .finally(() => this.loadings.enabling = !this.loadings.enabling)
          }
        }
      })
    },
    destroy(teacher) {
      this.$confirm({
        message: `¿Seguro que desea eliminar este ${teacher.is_coordinator ? 'coordinador' : 'profesor'}?`,
        button: {
          no: 'No',
          yes: 'Si'
        },
        callback: confirm => {
          if (confirm) {
            this.loadings.deleting = !this.loadings.deleting
            this.$repository.teachers
              .delete(teacher.id)
              .then(() => {
                this.$emit('updated')
              })
              .finally(() => this.loadings.deleting = !this.loadings.deleting)
          }
        }
      })
    },
    mappedCourses(teacher) {
      if (this.user.isCoordinator && this.$route.path === '/teachers') {
        return teacher?.course_sections?.flatMap(it => {

          if (this.coordinated_specializations.find(id => id.id === it.specialization_id)){
            return {
              title: `${it.course.title} (Sección: ${it.section.letter_code} - ${it.specialization.title})`,
              background: it.specialization.color,
              color: it.specialization.text_color,
              period_id: it.period_id,
              section_id: it.section_id,
              course_id: it.course_id,
              id: it.id
            }
          } else {
            
            return []
          }
        })?.filter(it => (it.period_id === this.period?.id))
      } else {
        return teacher?.course_sections?.flatMap(it => ({
          title: `${it.course.title} (Sección: ${it.section.letter_code} - ${it.specialization.title})`,
          background: it.specialization.color,
          color: it.specialization.text_color,
          period_id: it.period_id,
          section_id: it.section_id,
          course_id: it.course_id,
          id: it.id
        }))?.filter(it => it.period_id === this.period?.id) ?? []
      }
    },
    mappedSpecializations(teacher) {
      return teacher.specializations.map(spec => ({ title: spec.title, id: spec.id }))
    },
    courseSectionHasSchedules(courseSection, schedules) {
      return schedules.some($0 => $0.course_section_id === courseSection.id)
    },
    updateCoordinatorSpecialization(specialization_id, coordinator) {
      const found = this.specializations.find($0 => $0.id === parseInt(specialization_id))
      if (found) {
        this.$confirm({
          message: `¿Seguro que desea asignarle la especialidad ${found.title} a ${coordinator.first_name}?`,
          button: {
            no: 'No',
            yes: 'Si'
          },
          callback: confirm => {
            if (confirm) {
              this.specializationIds.push(parseInt(specialization_id));
              this.$repository.teachers
                .setSpecialization(coordinator.id, { specialization_ids: this.specializationIds, period_id: this.period.id })
                .then(() => {
                  this.$emit('updated')
                  // this.fetchCoordinators({ ...this.query })
                })
            }
          }
        })
      }
    },
    removeCoordinatorSpecialization(specialization_id, coordinator, title){
        this.$confirm({
          message: `¿Seguro que desea remover la especialidad ${title} a ${coordinator.first_name}?`,
          button: {
            no: 'No',
            yes: 'Si'
          },
          callback: confirm => {
            if (confirm) {
              this.specializationIds.push(parseInt(specialization_id));
              this.$repository.teachers
                .removeSpecialization(coordinator.id, { specialization_ids: this.specializationIds, period_id: this.period.id })
                .then(() => {
                  this.$emit('updated')
                  // this.fetchCoordinators({ ...this.query })
                })
            }
          }
        })

    }
  }
}
</script>
